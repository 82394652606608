import { logError } from 'fergy-core-react-logging';
import { type Maybe, type ProductVariantFieldsFragment } from '../../../__generated__/graphql-client-types';
import { type AddToCart } from '../../../hooks/add-to-cart/add-to-cart.hooks';
import { type ProductFamily } from '../../../types/product.types';

export enum ActionTypeLabelList {
	STANDARD = 'Add to Cart',
	BUY_IT_AGAIN = 'Buy It Again',
	VIEW_DETAILS = 'View Details',
	CONFIGURE = 'Configure',
	// The following are needed to determine how and if we display the add to cart button based on the productPricingDisplay property.
	CALL_FOR_PURCHASE = 'Contact to Purchase',
	CALL_FOR_PRICING = 'Contact for Pricing'
}

export enum ADD_TO_CART_BUTTON_TYPE {
	DEFAULT,
	STICKY,
	PLA,
	PLP,
	BUY_AGAIN,
	BUNDLE
}
/**
 * Determines the button type for Add to Cart
 */
export const getButtonType = (
	currentVariant: ProductVariantFieldsFragment,
	showAsEmployee: boolean,
	isManagedPurchaseDisplayActive?: boolean
): string => {
	let buttonType = 'STANDARD';
	if (!showAsEmployee) {
		if (isManagedPurchaseDisplayActive) {
			buttonType = currentVariant.productPricingDisplay || 'STANDARD';
		}
	}
	return buttonType;
};
/**
 * A fallback Add To Cart Action in case none is provided
 */
export const defaultAddToCartAction = (
	addToCart: AddToCart,
	pageName: string,
	cartQuantity: number,
	variantId: number,
	productFamily: Maybe<ProductFamily>,
	notifyDanger: (message: string) => void,
	notifySuccess: (message: string) => void
): void => {
	const analyticsData = {
		link: 'Add to Cart',
		linkName: 'Add to Cart',
		pageName,
		variantId,
		productFamily
	};
	const addToCartProps = { quantity: cartQuantity, analyticsData, variantId };
	addToCart(addToCartProps)
		.then(() => {
			notifySuccess('You have added an item to your cart');
		})
		.catch((errors) => {
			if (errors && errors.length > 0) {
				notifyDanger(errors[0].message);
			}
			logError('Add to cart failed unexpectedly');
		});
};
