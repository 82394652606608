import { type WarrantyBenefits } from '../../types/cart.types';

export const coverageBenefits: WarrantyBenefits[] = [
	{
		title: 'Dine-out Credit*',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: false,
		hasFiveYearPlan: true
	},
	{
		title: 'Laundry Credit*',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: false,
		hasFiveYearPlan: true
	},
	{
		title: 'Multi-Repair Safe Guard',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Repair or Replace Promise',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Fully Insured by A-Rated Insurance Carriers',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Toll Free Customer Service',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: '24/7 Claim Support',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Renewable*',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Power Surge Protection*',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'No Hidden Fees or Deductible',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	},
	{
		title: 'Additional 5-Year Major Component Coverage',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: false,
		hasFiveYearPlan: true
	},
	{
		title: 'Simple Claim Resolution',
		hasLimitedWarranty: false,
		hasTwoToThreeYearPlan: true,
		hasFiveYearPlan: true
	}
];
