import { type PriceOverrideUpdateType } from '../../types/price-override.types';

export const RECALC_TYPES: PriceOverrideUpdateType[] = [
	'DISCOUNT_PERCENT',
	'DISCOUNT_DOLLAR_AMOUNT',
	'MARGIN_PERCENT',
	'TOTAL_PRICE',
	'RESET',
	'REMOVE',
	'DISCOUNT_MODE'
];

export enum COMPETITOR {
	OTHER = 49,
	CUSTOMIZED_PRICING = 53
}

export enum ACKNOWLEDGEMENT_MESSAGE {
	NOT_PROFITABLE_AFTER_SHIPPING = 'I understand that this order may not be profitable after shipping is calculated.',
	ABOVE_REGULAR_PRICE = 'I understand that I am charging the customer more than the regular price for this order.',
	NOT_PROFITABLE_AFTER_DISCOUNTS = 'I understand that this order will almost certainly not be profitable with these discounts.',
	ADDITIONAL_DISCOUNTS = 'I understand that the promotions applied to this cart may be affected by additional discounts.',
	UMRP = "I understand that I have discounted a product with a unilateral manufacturer's retail price.",
	EXCEEDS_THRESHOLDS = 'I understand that this order exceeds my discount threshold and requires supervisor approval.',
	UNUSUALLY_LOW_COST = 'I have verified that the estimated costs we pay for this order are correct. Our costs appear unusually low which may indicate bad data.'
}

export enum ERROR_MESSAGE {
	HAS_CONFIRMATIONS = 'Review and agree to all acknowledgements regarding this cart before saving.',
	HAS_ERRORS = 'Resolve all product errors before saving. Expand a product row to view error details for that product.',
	NO_REASON = 'Select a reason for the discount.',
	NO_AUTO_PRICE_MATCH_REASON = 'Cannot update auto price match. Choose a different discount reason.',
	NO_SPECIAL_REASON = 'Cannot update discount with this reason. Choose a different discount reason.',
	UNIT_COST_TOO_LOW = 'Estimated unit cost is suspiciously low. Verify before discounting based on this cost.',
	UMRP_WARNING = "Item includes a unilateral manufacturer's retail price and should not be discounted.",
	INVALID_COMMENT_LENGTH = 'Discount must have a reason in the text field (10 character minimum).',
	GROSS_MARGIN_BELOW_ZERO = 'Gross margin is below zero.'
}

export enum ERROR_CODE {
	HAS_CONFIRMATIONS = 'HAS_CONFIRMATIONS',
	HAS_ERRORS = 'HAS_ERRORS',
	NO_REASON = 'NO_REASON',
	NO_AUTO_PRICE_MATCH_REASON = 'NO_AUTO_PRICE_MATCH_REASON',
	NO_SPECIAL_REASON = 'NO_SPECIAL_REASON',
	UNIT_COST_TOO_LOW = 'UNIT_COST_TOO_LOW',
	UMRP_WARNING = 'UMRP_WARNING',
	INVALID_COMMENT_LENGTH = 'INVALID_COMMENT_LENGTH',
	GROSS_MARGIN_BELOW_ZERO = 'GROSS_MARGIN_BELOW_ZERO',
	DISCOUNT_PRICE_INCORRECT = 'DISCOUNT_PRICE_INCORRECT',
	POS_WILL_BE_HELD_IN_OMC = 'POS_WILL_BE_HELD_IN_OMC'
}

export enum UPDATED_ERROR_SOLUTION_MESSAGE {
	HAS_CONFIRMATIONS = 'Agree to pricing acknowledgements or resolve profitability issues to continue.',
	HAS_ERRORS = 'Expand a product row to view error details for that product.',
	NO_REASON = 'Select a reason for the discount.',
	NO_AUTO_PRICE_MATCH_REASON = 'Choose a different discount reason.',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	NO_SPECIAL_REASON = 'Choose a different discount reason.',
	UNIT_COST_TOO_LOW = 'Verify before discounting based on this cost.',
	UMRP_WARNING = "Item includes a unilateral manufacturer's retail price and should not be discounted.",
	INVALID_COMMENT_LENGTH = 'Please describe your reason for discounting.',
	GROSS_MARGIN_BELOW_ZERO = 'Gross margin is below zero.',
	DISCOUNT_PRICE_INCORRECT = 'Verify discount price is correctly entered.',
	POS_WILL_BE_HELD_IN_OMC = 'This pricing includes a job quote. All related POs will automatically be placed on awaiting status in OMC.'
}

export enum UPDATED_ERROR_PROBLEM_MESSAGE {
	HAS_CONFIRMATIONS = 'Resolve Pricing Acknowledgements',
	HAS_ERRORS = 'Resolve All Product Errors',
	NO_REASON = 'Missing Reason',
	NO_AUTO_PRICE_MATCH_REASON = 'Cannot Update Auto Price Match',
	NO_SPECIAL_REASON = 'Cannot Update Discount with this Reason',
	UNIT_COST_TOO_LOW = 'Estimated Unit Cost is Suspiciously Low',
	UMRP_WARNING = 'Potential UMRP Violation',
	INVALID_COMMENT_LENGTH = 'Missing Comment',
	GROSS_MARGIN_BELOW_ZERO = '',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	DISCOUNT_PRICE_INCORRECT = '',
	POS_WILL_BE_HELD_IN_OMC = 'POs Will be Held in OMC'
}

export enum ACKNOWLEDGEMENT_CODE {
	NOT_PROFITABLE_AFTER_SHIPPING = 'NOT_PROFITABLE_AFTER_SHIPPING',
	ABOVE_REGULAR_PRICE = 'ABOVE_REGULAR_PRICE',
	NOT_PROFITABLE_AFTER_DISCOUNTS = 'NOT_PROFITABLE_AFTER_DISCOUNTS',
	ADDITIONAL_DISCOUNTS = 'ADDITIONAL_DISCOUNTS',
	UMRP = 'UMRP',
	EXCEEDS_THRESHOLDS = 'EXCEEDS_THRESHOLDS',
	UNUSUALLY_LOW_COST = 'UNUSUALLY_LOW_COST'
}

export const MINIMUM_REASON_LENGTH = 10;

export const INVALID_NUMBER_VALUE = 'N/A';

export const MIN_BASE_MARGIN = 85;
